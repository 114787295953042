import React from "react"
import HelmetTitle from "../components/utils"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-our-work.scss"

const OurWorkPage = () => {
  const tagline = "Case Studies",
    title = `Building stuff on its own is sufficient reward and reason for makers to build things. We do, however, want to do it with purpose. We create to influence our surroundings positively. We want the outcome of our efforts to benefit those who are around us.`
  return (
    <Layout>
      <div className="work mb-6">
        <HelmetTitle title="Case Studies" />
        <div className="work-banner pt-6 pb-6 mb-4 text-white">
          <Container fluid>
            <Row>
              <Col xs={{ size: 10, offset: 1 }}>
                <h1 className="mb-5 font-weight-bold">{tagline} </h1>
                <h3 className="mb-5 text-justify">{title}</h3>
              </Col>
            </Row>
          </Container>
        </div>

        <Container fluid>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <div className="line mb-3" />
              <p className="case-num" id="case1">
                Case Study 1
              </p>
              <p className="case-header font-weight-bold">
                Scaling cloud infrastructure
              </p>
              <p className="py-3">
                A Data Reseller approached Foretheta to modernize their existing
                cloud infrastructure. The client wanted to reduce the cost of
                ownership while having the flexibility to scale their data
                processing to higher traffic rate when needed.
              </p>
              <h6 className="case-sub-header font-weight-bold">Challenge</h6>
              <p>
                The client was using Rackspace Dedicated Servers. Their
                workloads were low most of the time, so they were mostly wasting
                server capacity when it was unused. To meet the potential demand
                spikes, they would need to lease even more servers.
              </p>
              <h6 className="case-sub-header font-weight-bold">Solution</h6>
              <p>
                Foretheta helped migrate the client's infrastructure to
                <a
                  href="https://aws.amazon.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  AWS{" "}
                </a>
                to enable them to autoscale and handle the increased workloads
                cost-effectively.
              </p>
              <p>
                he client's had a redundant (primary/backup) set of servers to
                host their Celery workers. The celery workers pulled jobs off a
                Redis cluster. A redundant MySQL server set was used for
                persistence.
              </p>
              <p>
                Foretheta replaced Redis with
                <a
                  href="https://aws.amazon.com/elasticache/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  ElastiCache
                </a>. MySQL servers were replaced with{" "}
                <a
                  href="https://aws.amazon.com/rds/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RDS
                </a>. The Celery workers were migrated to{" "}
                <a
                  href="https://aws.amazon.com/lambda/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AWS Lambda
                </a>.
              </p>
              <h6 className="case-sub-header font-weight-bold">Benefits</h6>
              <p>
                - Entirely move away from the dedicated server capacity in
                Rackspace.
              </p>
              <p>- Easily facilitate scaling out capacity based on demand.</p>
              <p>- Achieve a 63% reduction in costs.</p>

              <div className="line mb-3" />

              <p className="case-num" id="case2">
                Case Study 2
              </p>
              <p className="case-header font-weight-bold">
                Fast text analytics
              </p>
              <p className="py-3">
                A Patent Analytics company wanted to speed up their text
                analytics jobs that they ran on 2TB of compressed Patent text
                data in the cloud. The legacy application took days to run some
                of the data transformation operations on more than 100 Million
                documents.
              </p>
              <h6 className="case-sub-header font-weight-bold">Challenge</h6>
              <p>
                The client desperately needed to reduce the time required to run
                data transformation jobs on their patent data from days down to
                minutes, while remaining within budget. Therefore, they had to
                limit the size of the servers since fixed server capacity is
                expensive.
              </p>
              <h6 className="case-sub-header font-weight-bold">Solution</h6>
              <p>
                The client desperately needed to reduce the time required to run
                data transformation jobs on their patent data from days down to
                minutes, while remaining within budget. Therefore, they had to
                limit the size of the servers since fixed server capacity is
                expensive.
              </p>
              <p>
                Foretheta used
                <a
                  href="https://aws.amazon.com/emr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Amazon EMR{" "}
                </a>
                to make running Hadoop clusters smooth and fast on 100+ million
                documents.
              </p>
              <h6 className="case-sub-header font-weight-bold">Benefits</h6>
              <p>
                - Reduce the time it took to run their jobs from days down to
                minutes.
              </p>
              <p>
                - Run jobs on the 100 million plus documents simultaneously.
              </p>
              <p>
                - Permit the client's team to focus on innovating and creating
                more value-add services on top of the Patent data, instead of
                being bogged down by long-running jobs.
              </p>

              <div className="line mb-3" />

              <p className="case-num" id="case3">
                Case Study 3
              </p>
              <p className="case-header font-weight-bold">
                Aligning remote workforce
              </p>
              <p className="py-3">
                A major staffing agency approached Foretheta to help them align
                their workforce to their strategic plan. They wanted to build a
                custom application that would fit their workflow. Tracking the
                performance of each employee against their KPIs had to happen in
                a single place and on a regular cadence. Their existing system
                depended on slow tools that did not integrate well.
              </p>
              <h6 className="case-sub-header font-weight-bold">Challenge</h6>
              <p>
                The client had less than a hundred users, so they intended to
                keep costs low as they ramped up their project. At the same
                time, they wanted the ability to scale, when needed.
              </p>
              <p>
                The client was based out of a smaller region and cared about the
                ability to find a technical team that can maintain their project
                as they found success.
              </p>
              <h6 className="case-sub-header font-weight-bold">Solution</h6>
              <p>
                Foretheta built the application on top of
                <a
                  href="https://aws.amazon.com/elasticloadbalancing/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Elastic Load Balancing{" "}
                </a>
                and used
                <a
                  href="https://aws.amazon.com/cloudformation/aws-cloudformation-templates/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  CloudFormation templates{" "}
                </a>
                for automation. We used{" "}
                <a
                  href="https://aws.amazon.com/rds/postgresql/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Postgres RDS
                </a>{" "}
                as the backend database. We automated the deployment process
                using Git and a Continuous Integration pipeline.
              </p>
              <p>
                Foretheta developed the client's application using in NodeJS, a
                Javascript-based framework, ensuring they would be able to hire
                local talent to maintain the application.{" "}
              </p>
              <h6 className="case-sub-header font-weight-bold">Benefits</h6>
              <p>- Intuitive and up-to-date application.</p>
              <p>- Highly available and scalable architecture.</p>
              <p>- Low cost for a small number of users.</p>

              <div className="line mb-3" />

              <p className="case-num" id="case4">
                Case Study 4
              </p>
              <p className="case-header font-weight-bold">
                Testing the price impact of order book events
              </p>
              <p className="py-3">
                A client approached Foretheta to help validate an algorithmic
                trading strategy they were developing. The strategy involved
                testing the price impact of order book events. The client needed
                to implement and test the strategy in a short amount of time.
                The client required an option to run the trading strategy on
                live if needed.
              </p>
              <h6 className="case-sub-header font-weight-bold">Challenge</h6>
              <p>
                Speed was of the essence as the client was working under a
                strict deadline. The implementation needed to make use of
                existing systems that provided access to historical and live
                data.
              </p>
              <p>
                The client required a system that implements existing API for
                frequently used models in algorithmic trading strategies.
              </p>
              <h6 className="case-sub-header font-weight-bold">Solution</h6>
              <p>
                Foretheta and the client selected
                <a href="https://www.limebrokerage.com/">
                  {" "}
                  Lime Brokerage Studio{" "}
                </a>
                as it had access to live and historical data on the platform.
              </p>
              <p>
                Foretheta deployed the trading strategies to hosted machines
                with low-latency access to multiple US markets. They also had a
                rich set of features for backtesting intraday strategies. They
                also had a rich set of features for backtesting intraday
                strategies. They also had a rich set of features for backtesting
                intraday strategies.
              </p>
              <h6 className="case-sub-header font-weight-bold">Benefits</h6>
              <p>- Possibility to run trading strategy on live data</p>
              <p>
                - The quick deployment permits our client to catch the deadline
              </p>

              <div className="line " />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default OurWorkPage
